<template>
  <q-dialog ref="dialog" persistent @hide="hide">
    <q-card class="dialog-content">
      <q-btn class="dialog-close" flat round dense v-close-popup>
        <img src="../../assets/icons/close.svg" alt="Закрыть окно" title="Закрыть окно">
      </q-btn>
      <div class="dialog-header">
        <img src="../../assets/icons/dialog/add-term.svg" class="dialog-header-icon" alt="">
        {{ dialogTitle }}
      </div>

      <q-banner v-if="error" class="text-white bg-red">{{ error }}</q-banner>

      <q-form @submit.prevent="onSubmit">
        <q-input
          v-model="form.name.value"
          label="Название отрасли"
          outlined
          required
          :error="form.name.error != null"
          :error-message="form.name.error"
          maxlength="200"
        />
        <q-select
          v-model="form.parent.value"
          label="Родительская отрасль"
          outlined
          :options="form.parent.options"
          :error="form.parent.error != null"
          :error-message="form.parent.error"
        />

        <q-input
          v-model="form.description.value"
          label="Описание отрасли"
          outlined
          type="textarea"
          :error="form.description.error != null"
          :error-message="form.description.error"
        />

        <q-select
          v-model="form.weight.value"
          label="Вес для сортировки"
          outlined
          :options="weightOptions"
        />

        <div class="dialog-actions">
          <q-btn label="Отменить" flat class="cancel-button" @click.prevent="onCancelClick"/>
          <q-btn label="Сохранить" type="submit" color="primary" :loading="processing"/>
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import {api as categoriesApi} from '../../api/categories.js'

const rootParent = {label: ' - нет - ', value: '00000000-0000-0000-0000-000000000000'}

export default {
  setup() {
    const weightOptions = []
    for (let i = -100; i <= 100; i++) {
      weightOptions.push(i)
    }

    return {
      weightOptions
    }
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: true,
    },
  },
  emits: ['ok'],
  data() {
    return {
      processing: false,
      error: null,

      form: {
        name: {
          value: this.category.name,
          error: null,
        },
        parent: {
          value: this.category.parent ? {
            label: this.category.parent.name,
            value: this.category.parent.id
          } : rootParent,
          options: [],
          error: null,
        },
        description: {
          value: this.category.description,
          error: null,
        },
        weight: {
          value: this.category.weight,
          error: null,
        },
      }
    }
  },
  async created() {
    const options = [rootParent]
    const precessLevel = (category, level) => {
      options.push({label: '—'.repeat(level) + ' ' + category.name, value: category.id})
      category.children.forEach(category => {
        precessLevel(category, level + 1)
      })
    }

    (await categoriesApi.findTree()).forEach(category => {
      precessLevel(category, 0)
    })

    this.form.parent.options = options

    if (this.form.parent.value.value) {
      this.form.parent.value = options.find(option => this.form.parent.value.value === option.value)
    }
  },
  methods: {
    show() {
      this.$refs.dialog.show()
    },
    hide() {
      this.$refs.dialog.hide()
    },
    onCancelClick() {
      this.hide()
    },
    onSubmit() {
      const category = {}
      category.id = this.category.id
      category.name = this.form.name.value
      category.parent = this.form.parent.value.value
      category.description = this.form.description.value
      category.weight = this.form.weight.value

      this.processing = true
      categoriesApi
        .save(category)
        .then(category => {
          this
            .hook(category)
            .then(() => {
              this.$emit('ok', category)
              this.hide()
            })
        })
        .catch(errors => {
          this.processing = false
          if (Array.isArray(errors)) {
            errors.forEach(error => {
              if (error.source) {
                switch (error.source) {
                  case 'title':
                    this.form.name.error = error.title
                    break
                  case 'parent':
                    this.form.parent.error = error.title
                    break
                  case 'description':
                    this.form.description.error = error.title
                    break
                }
              } else {
                this.error = error.title
              }
            })
          }
        })
    },
  },
  computed: {
    dialogTitle() {
      return !this.category.id ? 'Новая отрасль' : 'Редактировать отрасль'
    },
  }
}
</script>
