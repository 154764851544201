<template>
  <q-btn>
    <img src="../../assets/icons/context-menu.svg" alt="" />
    <q-menu class="context-menu">
      <q-list>
        <q-item clickable v-close-popup @click="showEditCategoryDialog(category)">
          Изменить
        </q-item>
        <q-item v-if="canDelete(category)" clickable v-close-popup @click="showDeleteCategoryDialog(category)">
          Удалить
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import EditCategoryDialog from '../../dialogs/categories/EditCategoryDialog.vue'
import DeleteCategoryDialog from '../../dialogs/categories/DeleteCategoryDialog.vue'

export default {
  name: 'CategoryContextMenu',
  props: {
    category: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: true,
    },
  },
  methods: {
    showEditCategoryDialog(category) {
      this.$root
        .createDialog({
          component: EditCategoryDialog,
          componentProps: {
            category: category,
            hook: this.hook,
          },
        })
        .onOk(category => {
          this.$root.showSuccessMessage(`Отрасль «${category.name}» обновлена.`)
        })
    },
    showDeleteCategoryDialog(category) {
      this.$root
        .createDialog({
          component: DeleteCategoryDialog,
          componentProps: {
            category: category,
            hook: this.hook,
          },
        })
        .onOk(category => {
          this.$root.showSuccessMessage(`Отрасль «${category.name}» удалена.`)
        })
    },
    canDelete(category) {
      return category.fullTermsCount === 0 && category.childrenCount === 0
    },
  },
}
</script>
