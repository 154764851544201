<template>
  <div class="page-header">
    <router-link v-if="back" :to="backUrl" class="page-back">
      <q-icon>
        <img src="../assets/icons/back.svg" alt="">
      </q-icon>
    </router-link>
    <h1 class="page-title">{{ title }}</h1>
    <div class="page-actions">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    back: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    backUrl() {
      if (window.history.state.back) {
        return window.history.state.back
      }
      const parts = window.location.pathname.split('/')
      if (parts[1]) {
        return `/${parts[1]}`
      }
      return '/'
    }
  }
}
</script>

<style>
.page-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.page-back {
  padding: 0 18px 0 0;
  margin-top: -3px;
}
.page-title {
  flex-grow: 1;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}
</style>
